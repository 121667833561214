export const REQUEST_FAILED_LICENCE_HISTORY = 'REQUEST_FAILED_LICENCE_HISTORY';
export const REQUEST_LICENCE_HISTORY = 'REQUEST_LICENCE_HISTORY';
export const RECEIVE_LICENCE_HISTORY = 'RECEIVE_LICENCE_HISTORY';
export const SET_SORT_OPTIONS_LICENCE_HISTORY= 'SET_SORT_OPTIONS_LICENCE_HISTORY';
export const SET_PAGE_SIZE_LICENCE_HISTORY = 'SET_PAGE_SIZE_LICENCE_HISTORY';
export const SET_PAGE_NUMBER_LICENCE_HISTORY = 'SET_PAGE_NUMBER_LICENCE_HISTORY';
export const SET_FILTER_LICENCE_HISTORY = 'SET_FILTER_LICENCE_HISTORY';
export const REQUEST_FAILED_LICENCE_LIST = 'REQUEST_FAILED_LICENCE_LIST';
export const REQUEST_LICENCE_LIST = 'REQUEST_LICENCE_LIST';
export const RECEIVE_LICENCE_LIST = 'RECEIVE_LICENCE_LIST';
export const SET_SORT_OPTIONS_LICENCE_LIST= 'SET_SORT_OPTIONS_LICENCE_LIST';
export const SET_PAGE_SIZE_LICENCE_LIST = 'SET_PAGE_SIZE_LICENCE_LIST';
export const SET_PAGE_NUMBER_LICENCE_LIST = 'SET_PAGE_NUMBER_LICENCE_LIST';
export const SET_FILTER_LICENCE_LIST = 'SET_FILTER_LICENCE_LIST';