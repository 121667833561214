// reducer.js

import { SET_COMPANY_ID, SET_FILE_ID, SET_USER_ID, SET_INSTALLER_ID, SET_COMPUTER_NAME, SET_CUSTOMER_NAME } from './companyid.action_type';

const initialState = {
  companyId: "",
  fileId: "",
  userId: "",
  insatllerId: '',
  computerName:'',
  customerName:''
};

const companyidreducer = (state = initialState, action) => {

  switch (action.type) {
    case SET_COMPANY_ID:
      return {
        ...state,
        companyId: action.payload,
      };
    case SET_FILE_ID:
      return {
        ...state,
        fileId: action.payload,
      }
    case SET_USER_ID:
      return {
        ...state,
        userId: action.payload,
      }
    case SET_INSTALLER_ID:
      return {
        ...state,
        insatllerId: action.payload
      }
      case SET_COMPUTER_NAME:
        return{
          ...state,
          computerName:action.payload
        }
        case SET_CUSTOMER_NAME:
          return{
            ...state,
            customerName:action.payload
          }
    default:
      return state;
  }
};

export { companyidreducer };
