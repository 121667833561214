import React from 'react'
import { styles } from '../styles'
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../css/style.css';
import CustomArrow from '../utils/Arrow';
const Partner = () => {
  const images = [
    '/images/IITdelhi.png',
    '/images/iDEX.jpg',
    '/images/microsoft-Logo.png',
   
   
    '/images/niti-aayog-removebg-preview (1).png',
    '/images/dsciLogo.png',
    '/images/nasscom.jpg',
   
   
    
    // './logo192.png',
    '/images/science&security.jpg',
    '/images/n-coe-logo.png',
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 3000, 
    // fade: true,
    fade:false,
    centerMode: true,
    centerPadding: '0',
    easing: 'linear',
    nextArrow: <CustomArrow color="skyblue" direction="next" />,
    prevArrow: <CustomArrow color="skyblue" direction="prev" />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div className=" w-full h-screen  mx-auto  bg-opacity-40 bg-white   mb-[3vh]  py-[7vh]" id='partners'>
    <div
      className={` gap-y-2 inset-0  container mx-auto ${styles.paddingX} ${styles.paddingY} `}
    >
      <div className="featureHeading py-[3vh] ">
        <h1 className=" text-4xl font-extrabold font-heading mx-auto  font-high text-secondary py-7">Ecosystem Partners</h1></div>
      {/* <h1 className={`text-secondary text-[30px] font-high text-center mx-auto partner-underline`}>Ecosystem Partners</h1> */}
      <p className={`   2xl:text-2xl  font-medium py-[1vh]   text-justify  `}>Our global network of trusted partners enables us to increase our reach and connect with new organizations seeking to improve their IT and security operations</p>
     
        {/* <Slider {...settings} className='mx-auto  pb-9 sm:pb-14 flex-horizontal relative top-[15%] py-[8vh]'  > */}
          {/* < div className='flex-horizontal'> */}
          <Slider {...settings}>
        {images.map((image, index) => (
          <div className="h-[35vh] mx-auto w-full relative ">

          <div className=" py-[1vh] w-[95%] h-full  object-contain mx-auto " key={index} mx-auto >
               <img src={image} className=" h-[28vh] w-5/6 object-contain mx-auto absolute top-[50%] translate-y-[-50%]" alt="" /> 
               {index===2 && <p className='w-5/6  text-center absolute top-[82%] py-0 text-primary mx-auto left-[45%] translate-x-[-50%]'>Microsoft Partner Startup</p>}
               {index===0 && <p className='w-5/6  text-center absolute top-[82%] py-0 text-primary mx-auto left-[45%] translate-x-[-50%]'>IIT Delhi</p>}
          </div>
          </div>
        ))}
     {/* </div> */}
      </Slider>
      </div>
      </div>
  )
}


export default Partner