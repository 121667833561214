import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import IR from "./pages/IR";
import DLP from "./pages/DLP";
import Home from "./Home";
import Alerts from "./webapplication/pages/Alerts";
import SubPage from "./webapplication/pages/SubPage";
import AlertInstance from "./webapplication/pages/AlertInstance";
import { useDispatch, useSelector } from "react-redux";
import UuidAlerts from "./webapplication/pages/UuidAlerts";
import AllInstance from "./webapplication/pages/AllInstance";
import ActionCenterComputer from "./webapplication/pages/ActionCenterComputer";
import DirectoryService from "./webapplication/pages/DirectoryService";
import WorkQueue from "./webapplication/pages/WorkQueue";
// import CreateLicense from './webapplication/pages/licensing/CreateLicense';
import LinkingLicense from './webapplication/pages/licensing/LinkingLicense';
import LinkingLicence1 from './webapplication/pages/licensing/LinkingLicence1';
import { Installer } from './webapplication/pages/Installer';
import { Dashboard } from './webapplication/pages/Dashboard';
import SignUp1 from './pages/SignUp1';
import ThankYouSign from './pages/signUp/ThankYouSign';
import SignUp from './pages/signUp/PreSignUp';
// import PreSignUp1 from './pages/signUp/PreSignUp1';
import Login from './pages/login/Login';
import CompleteSignUp from './pages/signUp/CompletesSignUp';

import ForgotPassword from './pages/login/ForgotPassword';
import ErrorPage from './pages/ErrorPage';
import PreSignUp1 from './pages/Particles';
import LicenseDetail from './webapplication/pages/licensing/LicenseDetail';
import SubPage1 from './webapplication/pages/SubPage1';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { Installer1 } from './webapplication/pages/Installer1';
import { Helmet } from 'react-helmet';
import { useCookies } from 'react-cookie';
import CreateLicense2 from './webapplication/pages/licensing/CreateLicense2';
import LicenseDetail1 from './webapplication/pages/licensing/LicenseDetail1';

import LicenseHistoryCustomer from './webapplication/pages/licensing/LicenseHistoryCustomer';
// import { Helmet } from 'react-helmet-async';
// import CopyToClip from './webapplication/pages/CopyToClip';

// import ParticleBackground from './pages/Particles';

function App() {
  // const { companyId, fileid } = useSelector((state) => ({
  //   companyId: state.companyid.companyId,
  //   fileid: state.companyid.fileid,
  // }));
  // const isAuthenticated = useSelector((state) => state.auth.authenticated);
  // const [useRouteLoaderData,setUserData]=useState(null);
  // const [cookieCheck, setCookieCheck] = useState({});

  // useEffect(() => {
  //   axios.get(`${process.env.REACT_APP_HOST_NAME}/check-cookie`)
  //     .then((response) => {
  //       setCookieCheck(response.data);
  //       console.log(cookieCheck,"cookieCheck");
  //     })
  //     .catch((err) => {
  //       console.error(err);
  //     });
  // }, []);
  return (
    <BrowserRouter>
      <div className="relative">

        <ToastContainer />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/dlp" element={<DLP />} />
          <Route path="/ir-services" element={<IR />} />
          {/* <Route path='/presignup' element={<PreSignUp1 />} />
          <Route path="/signup" element={<SignUp1 />} /> */}
          {/* <Route path="/presignup" element={<PreSignUp1 />} /> */}
          {/* <Route path="/thankyouSignUp" element={<ThankYouSign />} />
          <Route path="/login" element={<Login />} />
          <Route path="/forgotPassword" element={<ForgotPassword />} />

          <Route path="/complete-signup/:companyid" element={<CompleteSignUp />} />

          <Route path="/cms/overview/:userid" element={<Dashboard />} />   */}
          {/* <Route path="/cms/history" element={<LicenseHistoryCustomer />} /> */}

          {/* <Route path="/cms/alerts/:companyId/:userid" element={<UuidAlerts />} />
          <Route path="/cms/alertInstance/:companyId/:fileid/:userid" element={<AlertInstance />} />
          <Route path="/cms/allinstances/:companyId/:userid" element={<AllInstance />} />
          <Route path="/cms/acComputer/:companyId/:userid" element={<ActionCenterComputer />} />
          <Route path="/cms/setting/directory/:companyId/:userId" element={<DirectoryService />} />
          <Route path="/cms/workQueue/:companyId/:userId" element={<WorkQueue />} /> */}

          {/* <Route path="/cms/createLicense/:userId" element={<CreateLicense2 />} /> */}
          {/* <Route path="/cms/linkLicence/:companyId/:userId" element={<LinkingLicense />} /> */}
          {/* <Route path="/cms/linkLicence1/:companyId/:userId" element={<LinkingLicence1 />} /> */}
          {/* <Route path="/cms/licenseInfo/:userId" element={<LicenseDetail1 />} /> */}
          {/* <Route path='subPage' element={<SubPage/>}/> */}
          {/* <Route path="/cms/installer/:companyid/:userid" element={<Installer1 />} /> */}
          {/* <Route path='/clip' element={<CopyToClip/>}/> */}
          {/* <Route path='/subpage1' element={<SubPage1/>}/> */}

        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
